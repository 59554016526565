$(function () {
    'use strict';

    // Initialize the jQuery File Upload widget:
    $('#fileupload').fileupload({
        url: 'index.php',
        // Enable image resizing, except for Android and Opera,
        // which actually support image resizing, but fail to
        // send Blob objects via XHR requests:
        disableImageResize: true,
        maxFileSize: 50000000,
        acceptFileTypes: /(\.|\/)(gif|jpe?g|png)$/i,
        autoUpload: false,
        submit: function (e, data) {
            $('#fileupload').fileupload('option', {
                formData: [
                    { name: 'name', value: $('#user-name').val() },
                    { name: 'notice', value: $('#user-notice').val() }
                ]
            });
        }
    });
});
